.project_heading {
  text-align: center;
  padding: 4rem 0 2rem 0;
}

.project_container {
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding-bottom: 5rem;
  max-width: 1240px;
}

.project_card {
  background-color: #f72798;
  padding: 1.2rem 1rem;
}

.project_card img {
  width: 100%;
}

.project_title {
  color: white;
  padding: 1rem 0;
}

.project_details p {
  padding-bottom: 1rem;
  font-size: 1.1rem;
  text-align: justify;
}

.project_button {
  background-color: #ebf400;
  padding: 0.5rem 1rem;
  color: black;
  border: solid 1px white;
}

@media screen and (max-width: 740px) {
  .project_container {
    max-width: 90%;
    margin: auto;
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 744px) and (max-width: 1250px) {
  .project_container {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 10px;
    margin-right: 10px;
  }
}

.work_container h2 {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;
}
