.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 2;
}

.header_bg {
  background-color: rgb(0, 0, 0, 0.85);
  transition: 0.5s;
}

.nav_menu {
  display: flex;
  flex-direction: row;
}

.nav_menu li {
  padding: 0 1rem;
}

.nav_menu li a {
  font-size: 1.5rem;
  font-weight: bold;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 1040px) {
  .nav_menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -3;
    transition: 0.2s;
    background: black;
  }

  .nav_menu {
    padding: 2rem 1rem;
  }

  .nav_menu.active {
    left: 0;
  }

  .nav_menu li a {
    font-size: 2.2rem;
    line-height: 5.5rem;
  }

  .hamburger {
    display: initial;
  }
}
