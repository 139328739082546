.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1240px;
  width: 100%;
  margin: 50px auto;
}

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 60px 0;
}

h2 {
  margin-bottom: 1rem;
}

.text_left,
.text_right {
  flex: 1;
  padding: 10px;
}

.image_left,
.image_right {
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.image_right {
  justify-content: flex-end;
}

.img_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
}

.img {
  width: 500px;
  height: 350px;
  margin-bottom: 10px;
  object-fit: cover;
  border-radius: 10px;
  border: 2px solid #f72798;
}

.about_content_paragraph span {
  font-weight: 700;
  color: #f72798;
}

button.btn_contact {
  margin-top: 50px;
}

.yellow {
  color: yellow;
  cursor: pointer;
}

@media (max-width: 1250px) {
  .image_left,
  .image_right {
    align-items: flex-start;
  }

  .img_container {
    align-items: flex-start;
  }
}

@media (max-width: 900px) {
  .section {
    flex-direction: column;
    margin: 20px 0;
  }

  .text_right,
  .text_left {
    order: 1;
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .image_right,
  .image_left {
    order: 2;
    width: 100%;
    justify-content: center;
    padding: 10px;
  }

  .img {
    width: 350px;
    height: 250px;
    object-fit: cover;
    justify-content: center;
  }

  .img_container {
    align-items: center;
  }
}
