.form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form_group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
  margin-bottom: 15px;
}

input,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
}

textarea {
  resize: vertical;
}

.btn {
  width: 100%;
}

/* Thank you Message styles */
.thank_you_message {
  text-align: center;
  padding: 40px 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid #f72798;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  animation: fadeIn 1s ease-in-out;
}

.thank_you_message h1 {
  font-size: 2.5em;
  color: white;
  margin-bottom: 10px;
}

.thank_you_message p {
  font-size: 1.2em;
  color: white;
  margin-bottom: 20px;
}

.giphy_embed {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1.5;
  }
}

/* Spinner Styles */
.spinner {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #007bff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
