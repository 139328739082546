.mask {
  width: 100%;
  height: 100vh;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.mask::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.7;
}

.hero .content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
  z-index: 1;
}

.hero .content h1 {
  font-size: 3.5rem;
  padding: 0.7rem 0 1.5rem;
  word-spacing: 10px;
}

.hero .content p {
  font-size: 1.4rem;
  font-weight: 200;
  text-transform: uppercase;
  word-spacing: 0.3rem;
}

.content .btn {
  margin: 1rem 0.2rem;
}

.btn {
  padding: 15px 35px;
  font-size: 1.4rem;
  text-transform: uppercase;
  background: #f72798;
  color: black;
  border: 1px solid white;
  font-weight: 600;
  cursor: pointer;
}

.btn_light {
  background: transparent;
  color: white;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  transition: 0.3s;
}

@media screen and (max-width: 640px) {
  .btn {
    padding: 10px 25px;
    font-size: 1.2rem;
  }
  .hero .content h1 {
    font-size: 2.5rem;
  }
  .hero .content p {
    font-size: 1.1rem;
    font-weight: 200;
    text-transform: uppercase;
    word-spacing: 0.3rem;
  }
}
